//#region Import
import { CommonModule } from '@angular/common';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { DocumentComponent } from '../options/document/document.component';
import { FactureEntrantComponent } from '../options/facture-entrant/facture-entrant.component';
import { IndexComponent } from '../options/index/index.component';
import { InformationClientComponent } from '../options/information-client/information-client.component';
import { InformationSegmentationComponent } from '../options/information-segmentation/information-segmentation.component';
import { LocalisationComponent } from '../options/localisation/localisation.component';
import { ProprietaireComponent } from '../options/proprietaire/proprietaire.component';
import { ReferenceBancaireComponent } from '../options/reference-bancaire/reference-bancaire.component';
//#endregion

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import {
  LibelleEtatAbonnement,
  TextEtatAbonnement
} from 'src/app/api/models/enum/EtatAbonnement.enum';
import { PropertiesWizard } from 'src/app/api/models/interface/DataKeyValue';
import { TiersResponse } from 'src/app/api/models/response/tiers/tiersResponse';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { WizardStep } from 'src/app/shared/models/wizardStep';
import { BaseComponent } from '../../base/base.component';

export interface etatWizard {
  class: string;
  icone: string;
  text: string;
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  imports: [
    CommonModule,
    ChipModule,
    TooltipModule,
    LocalisationComponent,
    IndexComponent,
    InformationClientComponent,
    ProprietaireComponent,
    ReferenceBancaireComponent,
    InformationSegmentationComponent,
    DocumentComponent,
    FactureEntrantComponent
  ],
  standalone: true
})
export class WizardComponent extends BaseComponent implements OnInit {
  @Input() wizardSteps!: WizardStep[];
  @Input() typeWyzard!: string;

  @Input() editIC: boolean = false;
  @Input() etatVisible = false;
  @Input() miseEnService: boolean = false;

  @Input() codeINSEE!: string;
  @Input() idTiersAbo!: string;
  @Input() idTiersDest!: string;
  @Input() tiers!: TiersResponse;

  @Input() numAbonnement!: string | null;
  @Input() isTipForm: boolean = false;
  @Input() submitIsLoading: boolean = false;
  @Output() submit = new EventEmitter<boolean>();

  currentStep!: WizardStep;
  lastStep: boolean = false;
  alreadyOpen: boolean = false;

  etat: string = LibelleEtatAbonnement.EnService;
  etatIsVisible: boolean = false;
  infosEtatAbonnement!: etatWizard;

  constructor(
    private router: Router,
    store: Store<State>,
    resources: AppResource,
    messageService: MessageService
  ) {
    super(store, resources, messageService);
    this.getInfosEtatAbonnement(this.etat);
  }

  override async ngOnInit() {
    this.currentStep = this.wizardSteps[0];
  }

  /**
   *
   * @param submit boolean
   * @description Emettre l'événement de soumission du formulaire
   */
  submitEvent(submit: boolean) {
    this.submit.emit(submit);
  }

  /**
   *
   * @param step WizardStep
   * @description Mettre à jour la classe de l'étape du Wizard
   */
  updateClass(step: WizardStep) {
    if (this.isTipForm && step.tipIsPossible) {
      if (step.show && !step.valid) return 'tip-route-active';
      else if (!step.show && step.valid) return 'tip-route-valid';
      else return 'route-inactive';
    } else {
      if (!step.show && !step.valid) return 'route-inactive';
      else if (step.show && !step.valid) return 'route-active';
      else if (!step.show && step.valid) return 'route-valid';
      else return 'route-inactive';
    }
  }

  /**
   *
   * @param step WizardStep
   * @description Mettre à jour l'icône de l'étape du Wizard
   */
  updateIcon(step: WizardStep) {
    if (this.isTipForm && step.tipIsPossible) {
      if (step.show && !step.valid) return step.srcIconShow;
      else if (!step.show && step.valid) return step.srcIconTip;
      else return step.srcIcon;
    } else {
      if (!step.show && !step.valid) return step.srcIcon;
      else if (step.show && !step.valid) return step.srcIconShow;
      else if (!step.show && step.valid) return step.srcIconValid;
      else return step.srcIcon;
    }
  }

  /**
   *
   * @param event boolean
   * @param currentStep WizardStep
   * @description Passer à l'étape suivante du Wizard
   */
  nextStep(event: boolean, currentStep: WizardStep) {
    let nextStep = this.wizardSteps.find(step => step.step === currentStep.step + 1);
    let nextStep_bis = null;

    if (nextStep && nextStep.ignoreStep)
      nextStep_bis = this.wizardSteps.find(step => step.step === currentStep.step + 2);

    if (nextStep) {
      currentStep.show = false;
      currentStep.valid = true;
      nextStep.show = nextStep_bis ? false : true;
      nextStep.valid = nextStep_bis ? true : false;

      if (nextStep_bis) {
        nextStep_bis.show = true;
        nextStep_bis.valid = false;
      }

      this.currentStep = nextStep_bis ? nextStep_bis : nextStep;
    }

    this.getLastStep();
  }

  /**
   *
   * @param event boolean
   * @param currentStep WizardStep
   * @description Revenir à l'étape précédente du Wizard
   */
  previousStep(event: boolean, currentStep: WizardStep) {
    let previousStep = this.wizardSteps.find(step => step.step === currentStep.step - 1);
    let previousStep_bis = null;
    if (previousStep?.ignoreStep && currentStep.step >= 2) {
      previousStep_bis = this.wizardSteps.find(step => step.step === currentStep.step - 2);
    }

    if (previousStep) {
      currentStep.show = false;
      currentStep.valid = false;
      previousStep.show = previousStep_bis ? false : true;
      previousStep.valid = false;

      if (previousStep_bis) {
        previousStep_bis.show = true;
        previousStep_bis.valid = false;
      }

      this.currentStep = previousStep_bis ? previousStep_bis : previousStep;
    }
  }

  /**
   *
   * @param event boolean
   * @description Permet de savoir si le formulaire de référence bancaire est un tip ou non
   */
  isTipFormEvent(event: boolean) {
    this.isTipForm = event;
  }

  /**
   *
   * @param currentStep string
   * @param step string
   * @description Afficher l'étape actuelle du Wizard
   */
  showStep(currentStep: string, step: string): boolean {
    if (currentStep == step) return true;
    else return false;
  }

  /**
   *
   * @param wizardStep WizardStep
   * @description Sélectionner une étape du Wizard
   */
  selectStep(wizardStep: WizardStep) {
    if (wizardStep.step < this.currentStep.step) {
      this.currentStep = wizardStep;

      this.wizardSteps.forEach(res => {
        if (res.step < wizardStep.step) {
          res.show = false;
          res.valid = true;
        } else if (res.step == wizardStep.step) {
          res.show = true;
          res.valid = false;
        } else if (res.step > wizardStep.step) {
          res.show = false;
          res.valid = false;
        }
      });
    }
  }

  /**
   *
   * @returns string
   * @description Mettre à jour le nombre de colonnes du Wizard
   */
  updateColumnWizard(): string {
    return 'grid-cols-' + this.wizardSteps.length;
  }

  /**
   * @description Déterminer si l'étape actuelle est la dernière étape du Wizard
   */
  getLastStep() {
    const lastStep = this.wizardSteps.slice(-1);

    if (lastStep[0].step == this.currentStep.step) this.lastStep = true;
    else this.lastStep = false;
  }

  /**
   * @description Récupération des informations de l'état de l'abonnement à afficher
   */
  getInfosEtatAbonnement(etat: string) {
    switch (etat) {
      case LibelleEtatAbonnement.EnService:
        this.infosEtatAbonnement = {
          class: 'service',
          icone: 'pi pi-play',
          text: TextEtatAbonnement.EnService
        };
        break;
      case LibelleEtatAbonnement.EnAttentePremiereUtilisation:
        this.infosEtatAbonnement = {
          class: 'attente',
          icone: 'icon waterpIcons-Timelapse',
          text: TextEtatAbonnement.EnAttentePremiereUtilisation
        };
        break;
      default: {
        this.infosEtatAbonnement = { class: 'hidden', icone: '', text: TextEtatAbonnement.Inconnu };
        break;
      }
    }
  }

  /**
   * @param propertiesWizard Propriété de l'étape du Wizard
   * @description Détermine vers quelle étape rediriger l'utilisateur
   */
  updateWizardCondition(propertiesWizard: PropertiesWizard) {
    let step = this.wizardSteps.find(x => x.step == propertiesWizard.Index);

    if (step) {
      if (propertiesWizard.Properties.length > 0) {
        propertiesWizard.Properties.forEach(property => {
          if (step != undefined) step[property.Key] = property.Value;

          if (
            this.currentStep.step == propertiesWizard.Index &&
            property.Key == 'ignoreStep' &&
            property.Value == true
          ) {
            this.nextStep(true, this.wizardSteps[propertiesWizard.Index]);
          }
        });
      }
      this.wizardSteps[propertiesWizard.Index - 1] = step;
    }
  }
}
